export const educationData = [
    {
        "id": 1,
        "institution": "",
        "course": "Formation continue en autodidacte sur les technologies modernes",
        "startYear": "2021",
        "endYear": "Présent"
    },
    {
        "id": 3,
        "institution": "Haute Ecole de Management et d'Informatique (Dakar)",
        "course": "Licence en Génie Logiciel",
        "startYear": "2019",
        "endYear": "2022"
    }
]
