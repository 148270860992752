import React from 'react'
import { Helmet } from 'react-helmet'
import { motion } from 'framer-motion'
import { Navbar, Footer, Landing, About, Skills, Education, Experience, Contacts, Projects, Services, Achievement, IconMenu } from '../../components'
import { headerData } from '../../data/headerData'

const pageVariants = {
    initial: {
        opacity: 0,
        y: 50
    },
    in: {
        opacity: 1,
        y: 0
    },
    out: {
        opacity: 0,
        y: -50
    }
};

const pageTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 0.5
};

function Main() {
    return (
        <motion.div
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
            transition={pageTransition}
        >
            <Helmet>
                <title>{headerData.name} - Portfolio</title>
            </Helmet>

            <Navbar />        
            <Landing />
            <About />
            <Education />
            <Skills />
            <Experience />
            <Projects />
            <Achievement />
            <Services />
            <Contacts />
            
            <Footer />
        </motion.div>
    )
}

export default Main
